<template>
  <!-- 培训报名 -->
  <div class="signUpDetails">
    <van-nav-bar title="培训报名"
                 left-text=""
                 left-arrow
                 @click-left="onClickLeft"
                 @click-right="onClickRight" />
    <div class="overflow_div">
      <van-form @submit="onSubmit">
        <van-field class="margin_cell"
                   readonly
                   clickable
                   required
                   :value="signTime"
                   label="报名日期"
                   input-align="right"
                   name="signTime"
                   placeholder="请选择"
                   right-icon="arrow"
                   @click="showTime = true" />
        <van-popup v-model="showTime"
                   round
                   position="bottom">
          <van-datetime-picker v-model="currentDate"
                               type="date"
                               title="选择报名日期"
                               :min-date="minDate"
                               @confirm="onConfirm"
                               @cancel="showTime = false" />
        </van-popup>
        <van-field class="bring_out"
                   v-model="courseName"
                   name="courseName"
                   label="课程名称"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="lecturerName"
                   name="lecturerName"
                   label="讲师"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="planType"
                   name="planType"
                   label="计划类别"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="trainingType"
                   name="trainingType"
                   label="培训类别"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="trainingForm"
                   name="trainingForm"
                   label="培训形式"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="courseBgDate"
                   name="courseBgDate"
                   label="课程开始时间"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="bring_out"
                   v-model="courseEdDate"
                   name="courseEdDate"
                   label="课程结束时间"
                   placeholder="请输入"
                   input-align="right"
                   readonly />
        <van-field class="margin_cell"
                   v-model="department"
                   name="department"
                   label="部门"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   right-icon="arrow"
                   required />
        <van-field v-model="job"
                   name="job"
                   label="岗位"
                   placeholder="请选择"
                   input-align="right"
                   readonly
                   right-icon="arrow"
                   required />
        <van-field v-model="name"
                   name="name"
                   label="姓名"
                   placeholder="请输入"
                   input-align="right"
                   required />
        <van-field class="cell_after"
                   v-model="jobNum"
                   name="jobNum"
                   label="工号"
                   placeholder="请输入"
                   input-align="right"
                   required />
        <van-field v-model="Remarks"
                   name="Remarks"
                   label="备注"
                   rows="5"
                   class="remark"
                   autosize
                   type="textarea"
                   maxlength="150"
                   placeholder="请输入...(最多可输入150字)"
                   show-word-limit />
        <div class="Training_agreement">
          <van-checkbox v-model="checked"
                        icon-size="18px">
            <span>同意</span>
            <span class="agreement">培训协议</span>
            <span>服务与隐私</span>
          </van-checkbox>
        </div>
        <div class="footer_btn">
          <van-button type="info"
                      native-type="submit">报名</van-button>
        </div>

      </van-form>
    </div>
  </div>
</template>

<script>
import { Toast } from 'vant'
export default {
  data () {
    return {
      signTime: '',
      showTime: false,
      minDate: new Date(),
      // maxDate: new Date(2025, 10, 1),
      currentDate: new Date(),
      courseName: '标准固化模型',
      lecturerName: '卞耕方T',
      planType: '年度计划',
      trainingType: '内训',
      trainingForm: '现场培训',
      courseBgDate: '2021-05-13 12:32:00',
      courseEdDate: '2021-05-13 13:32:00',
      department: '',
      job: '',
      name: '',
      jobNum: '',
      Remarks: '',
      checked: false

    }
  },
  methods: {
    onClickLeft () {
      this.$router.push('/trainingRegister')
    },
    onClickRight () { },
    // 选择预入职日期确认
    onConfirm (value) {
      let dateTime = ''
      if (value)
      {
        let y = value.getFullYear()
        let m = value.getMonth() + 1
        m = m < 10 ? ('0' + m) : m
        let d = value.getDate()
        d = d < 10 ? ('0' + d) : d
        // let h = value.getHours()
        // h = h < 10 ? ('0' + h) : h
        // let M = value.getMinutes()
        // M = M < 10 ? ('0' + M) : M
        //  let s =date.getSeconds()
        //  s = s < 10 ? ('0' + s) : s
        // dateTime = y + '-' + m + '-' + d + ' ' + h + ':' + M;
        dateTime = y + '-' + m + '-' + d
      }
      this.signTime = dateTime
      this.showTime = false
    },
    onSubmit (values) {
      if (this.checked)
      {
        this.$router.push('/signUpSuccessfully')
        console.log('submit', values);
      } else
      {
        Toast({
          message: '请先勾选协议！！！',
          position: 'top',
        });
      }
    },
  }
}
</script>

<style lang="less" scoped>
.signUpDetails {
  height: 100vh;
  overflow: hidden;
  background: #eceaea;
  .van-nav-bar {
    background: #2b8df0;
    /deep/ .van-nav-bar__left {
      .van-icon {
        color: #fff;
      }
    }
    /deep/.van-nav-bar__title {
      color: #fff;
      font-size: 36px;
      font-family: Source Han Sans CN;
    }
    ::v-deep .van-nav-bar__right {
      .van-nav-bar__text {
        color: #fff;
        font-family: Source Han Sans CN;
      }
    }
  }
  .overflow_div {
    overflow: auto;
    height: calc(100% - 100px);
    .van-form {
      // overflow: auto;
      // height: calc(100% - 235px);
      ::v-deep .van-field__label {
        color: #000;
      }
      .margin_cell {
        margin-top: 20px;
      }
      .cell_after::after {
        border-bottom: unset;
      }
      .bring_out {
        ::v-deep.van-field__control {
          color: #999999;
        }
      }
    }
    .Training_agreement {
      background: #fff;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100px;
      span {
        font-size: 28px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        color: #000000;
      }
      ::v-deep.van-checkbox__label {
        display: flex;
        align-items: center;
      }
      .agreement {
        color: #2b8df0;
      }
    }
    .footer_btn {
      display: flex;
      align-items: center;
      // position: absolute;
      // bottom: 0;
      width: 100%;
      height: 120px;
      padding: 20px;
      box-sizing: border-box;
      background: #fff;
      justify-content: center;
      .van-button {
        width: 80%;
        border-radius: 10px;
        .van-button__text {
          font-size: 32px;
          font-family: Source Han Sans CN;
        }
      }
    }
  }
}
</style>